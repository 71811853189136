<template>
    <v-container  style="margin-top:60px;margin-bottom: 100px;">
      <v-card class="simple_card" style="margin-bottom:15px;" elevation="10" v-for="(content, i) in news" :key="i">
            <v-card-title style="justify-content: center;">
        
           <!-- <span class="title font-weight-light">
             <br>{{content.date}}</span> -->
            <span style="" v-html="content.header"></span>
          </v-card-title>
            <v-card-text v-html="content.content"></v-card-text>
        </v-card>
        


    </v-container>
</template>



<script>
import btnrnd from "../components/btnrnd.vue";
// eslint-disable-next-line no-unused-vars
import axios from "axios";
// eslint-disable-next-line no-unused-vars
import { API_LOCATION } from "../../config";
export default {
  name: "Info",
  components: {
    // eslint-disable-next-line vue/no-unused-components
    btnrnd,
  },
  data: () => ({
    news:[],
  }),
  computed: {
    
      title() {
        return this.$t('title')
      },
  },
  watch:{
      title: function(){
        this.$store.dispatch("page_title_set", this.title);
      }
  },
  mounted() {
     axios
        .post(
          API_LOCATION,
          { data: "component_data", action: "get_news30", id: this.task_id },
          { headers: { "content-type": "application/x-www-form-urlencoded" } }
        )
        .then((resp) => {
          
          this.news=resp.data;
          console.log(this.news);
        });
  },
  created() {
    this.$store.dispatch("page_title_set", this.title);
    this.$store.dispatch("profileset");
  },
};
</script>


<i18n>
{
  
  "ua":{
      "title":"Планограми"


      

  }
}
</i18n>